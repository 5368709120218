import Vue from 'vue'
import Vuex from 'vuex'

import cache from '../cache/cache.js'
Vue.use(Vuex)


const store = new Vuex.Store({
	state: {
		isDiagnosisLogined: true,
		uniqueID:null
	},
	actions: {
		setIsDiagnosisLogined(context, isDiagnosisLogined) {
			context.commit('setIsDiagnosisLogined', isDiagnosisLogined);
		}

	},
	mutations: {
		setIsDiagnosisLogined(state, isDiagnosisLogined) {
			state.isDiagnosisLogined = isDiagnosisLogined;
		},
		setUniqueID(state,id){
			state.uniqueID = id;
			cache.uniqueID = id;
		}
	},
	getters: {}
})
export default store

// export default new Vuex.Store({
// 	//state相当于组件中的data，专门用来存放全局数据
// 	state: {
// 		token: null,
// 		login:''
// 	},
// 	//相当于组件中的computed,只不过getters是全局的
// 	getters: {

// 	},
// 	//相当于组件中的methods，但是它不能使用异步
// 	mutations: {
// 		//设置token
// 		setToken(state, token) {
// 			state.token = token;
// 			state.login = true;
// 			localStorage.setItem('token', JSON.stringify(token));
// 		},
// 		removeToken(state){
// 			state.token = null;
// 			state.login = false;
// 			localStorage.removeItem('token');
// 		}

// 	},
// 	//专门处理异步，实际还是调用mutations
// 	actions: {},
// 	modules: {}
// })

import {
	get,
	post,
	put,
	remove,
	postJson,
	postData,
	upload,
	download,
    putJson
} from '../request/request';
// import { Loading } from 'element-ui';
//定义一个插件
export default {
	//插件中必须包含install方法
	install: function(Vue) {
		//给vue混入成员
		Vue.mixin({
			methods: {
				$get(url, param) {
					return get(url, param)
				},
				$post(url, param) {
					return post(url, param)
				},
				$postData(url, param) {
					return postData(url, param)
				},
				$put(url, param) {
					return put(url, param)
				},
				$remove(url, param) {
					return remove(url, param)
				},
				$postJson(url, param) {
					return postJson(url, param)
				},
				$upload(file) {
					return upload("/base-api/file/upload", file)
				},
				$uploadWithName(file) {
					return upload("/base-api/file/upload-with-filename", file)
				},
				$download(url, param) {
					return download(url, param)
				},
                $putJson(url, param) {
					return putJson(url, param)
				},
				// $showLoading(options){
				// 	Loading.service(options);
				// },
				// $hideLoading(options){
				// 	Loading.service(options);
				// }
			}
		})
	}
}

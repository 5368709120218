//注册element ui 组件库
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//设置el-dialog点击遮罩层不能关闭
ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.use(ElementUI);

//注册myplugin插件
import myPlugin from './myPlugin'
Vue.use(myPlugin);

Vue.prototype.openLoading = function() {
	const loading = this.$loading({ // 声明一个loading对象
		lock: true, // 是否锁屏
		text: '正在加载...', // 加载动画的文字
		spinner: 'el-icon-loading', // 引入的loading图标
		background: 'rgba(0, 0, 0, 0.3)', // 背景颜色
		target: '.sub-main', // 需要遮罩的区域
		body: true,
		customClass: 'mask' // 遮罩层新增类名
	})
	setTimeout(function() { // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
		loading.close(); // 关闭遮罩层
	}, 10000)
	return loading;
}

import Vue from 'vue'
import App from './App.vue'
import configApi from './config/configApi'

//全局引用
import $ from 'jquery'
//导入路由
import router from './router'
//导入状态管理
import store from './store/index.js'
//导入插件
import './plugin'

//导入动画库
import animated from 'animate.css'

// 导入echarts
import * as echarts from 'echarts';

import 'echarts-gl';

//导入省略号插件
import ellipsis from '@hyjiacan/vue-ellipsis'

//导入适配
import 'lib-flexible'

//引入three.js
import * as THREE from "three";

//导入按钮权限插件
import hasPerm from './directives/hasPerm.js'
// 引入中间件组件
import Bus from './bus/index'
//引入富文本编辑器样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

window.THREE = THREE


Vue.config.productionTip = false

Vue.prototype.$echarts = echarts
Vue.prototype.$ = $
Vue.prototype.Bus = Bus

Vue.prototype.$store = store

Vue.prototype.photoURL = configApi.photoURL

Vue.prototype.baseURL = configApi.baseURL
// 动画库
Vue.use(animated)

// 省略号
Vue.use(ellipsis)
Vue.directive('hasPerm', hasPerm);

Vue.mixin({
	// 上传

	//  handleRequest(param) {
	//   let fileObject = param.file;
	//   let formData = new FormData();
	//   formData.append("file", fileObject);
	//   return new Promise((resolve)=>{
	//     this.$post(
	//       url,
	//       formData,{header:{'content-type':'multipart/form-data;charset=UTF-8'}}).then((res)=>{
	//         if(res.code == '1000'){
	//           resolve(res.data)
	//         }
	//       })
	//   })
	// },

	// handleAvatarSuccess(res, file) {
	//   console.log(res,'ressres');
	//   console.log(file,'filessd');
	//   this.$message.success("上传成功");
	// }

})
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

Vue.use(VXETable)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

import caches from '@cache/cache.js';
/**
 * 判断权限标识-and
 * 即同时拥有权限标识
 * 
 */
export default {
	inserted(el, binding, vnode) {
		let flag = true;
		for (let code of caches.codes.get()) {
			if (code == binding.value) {
				flag = false;
				break;
			}
		}
		if (flag) {
			el.parentNode.removeChild(el);
		}
	}
}

import Vue from 'vue'
import store from '../store/index.js'
import VueRouter from 'vue-router'
// import store from '../store'
Vue.use(VueRouter)

//路由信息数字 

//平台端
const platform = {
	path: '/platform',
	name: 'platform',
	component: () => import('@/page/views/platform/platformIndex'),
	children: [
		// 监控中心
		{
			path: 'monitoring',
			name: 'platformMonitoring',
			component: () => import('@/page/views/platform/monitoring/monitoring.vue')
		},
		//设备中心
		{
			path: 'equipment',
			name: 'platformEquipment',
			component: () => import('@/page/views/platform/equipment/equipment.vue'),
			// redirect: "equipment/equipmentLine",
			children: [{
					path: 'equipmentLine', //设备中心-设备线
					name: 'platformEquipmentLine',
					component: () => import(
						'@/page/views/platform/equipment/equipmentLine/list.vue'
					)
				},
				{
					path: 'gather',
					name: 'platformGather', //设备中心-采集站
					component: () => import(
						'@/page/views/platform/equipment/gather/list.vue'
					)
				},
				{
					path: 'healthStatusReport', //设备中心-健康状态报告
					name: 'platformJkzkbg',
					component: () => import(
						'@/page/views/platform/equipment/healthStatusReport/list.vue'
					)
				},
				{
					path: 'diagnosticMaintenanceReport', //设备中心-诊断维修报告
					name: 'platformZxwxbg',
					component: () => import(
						'@/page/views/platform/equipment/diagnosticMaintenanceReport/list.vue'
					)
				},
				{
					path: 'knowledge', //设备中心-设备知识库
					name: 'platformSbzsk',
					component: () => import(
						'@/page/views/platform/equipment/knowledge/list.vue'
					)
				},
				{
					path: 'equipmentSensor', //设备中心-油液传感器
					name: 'platformSensor',
					component: () => import(
						'@/page/views/platform/equipment/sensor/list.vue'
					)
				},
				{
					path: 'zhengxianModel', //模型管理-整线模型
					name: 'platformZhengxianModel',
					component: () => import(
						'@/page/views/platform/equipment/zhengxianModel/list.vue'
					)
				},
				{
					path: 'dantiModel', //模型管理-单体模型
					name: 'platformDantiModel',
					component: () => import(
						'@/page/views/platform/equipment/dantiModel/list.vue'
					)
				},
				{
					path: 'bearing', //模型管理-轴承参数
					name: 'platformBearing',
					component: () => import(
						'@/page/views/platform/equipment/bearing/list.vue'
					)
				},
				{
					path:'binding',//设备中心-真的测点绑定
					name: 'platformBinding',
					component: () => import(
						'@/page/views/platform/equipment/binding/list.vue'
					)
				},
				{
					path: 'ruibang', //设备中心-瑞邦数据绑定
					name: 'platformRuibang',
					component: () => import(
						'@/page/views/platform/equipment/ruibang/list.vue'
					)
				},
				{
					path:'tempBinding',//设备中心-温度测点绑定
					name: 'platformTempBinding',
					component: () => import(
						'@/page/views/platform/equipment/tempBinding/list.vue'
					)
				},
				{
					path:'zhihuanBinDing',//设备中心-智寰绑定（汇总）
					name: 'platformZhihuanBinDing',
					component: () => import(
						'@/page/views/platform/equipment/zhihuanBinDing/list.vue'
					)
				},
				{
					path:'winccBinDing',//设备中心-wincc绑定
					name: 'platformWinccBinDing',
					component: () => import(
						'@/page/views/platform/equipment/winccBinDing/list.vue'
					)
				},
				{
					path:'rongzhiBinDing',//设备中心-容知绑定
					name: 'platformRongzhiBinDing',
					component: () => import(
						'@/page/views/platform/equipment/rongzhiBinDing/list.vue'
					)
				},
				{
					path:'zsbinding',//设备中心-转速测点绑定
					name: 'platformZsbinding',
					component: () => import(
						'@/page/views/platform/equipment/zsbinding/list.vue'
					)
				},
				{
					path:'selfInspection',//设备中心-自检测
					name: 'platformSelfInspection',
					component: () => import(
						'@/page/views/platform/equipment/selfInspection/list.vue'
					)
				},
				{
					path:'prewarning',//设备中心-测点报警值设置
					name: 'platformPrewarning',
					component: () => import(
						'@/page/views/platform/equipment/prewarning/list.vue'
					)
				},
				{
					path: 'gear', //模型管理-齿轮参数
					name: 'platformGear',
					component: () => import(
						'@/page/views/platform/equipment/gear/list.vue'
					)
				},
				{
					path: 'parameters', //模型管理-单体设备参数
					name: 'platformParameters',
					component: () => import(
						'@/page/views/platform/equipment/parameters/list.vue'
					)
				},
				{
					path: 'alarmInfo',
					name: 'platformalarmInfo', //设备报警-报警信息
					component: () => import(
						'@/page/views/platform/equipment/alarm/alarmInfo.vue'
					)
				},
				{
					path: 'alarmList',
					name: 'platformalarmList', //设备报警-历史报警列表
					component: () => import(
						'@/page/views/platform/equipment/alarm/alarm.vue'
					)
				},
				{
					path: 'alarmMeas',
					name: 'platformalarmMeas', //设备报警-历史报警测点数据
					component: () => import(
						'@/page/views/platform/equipment/alarm/meas.vue'
					)
				},
                {
					path: 'actualSpeed',
					name: 'platformActualSpeed', //设备中心-实际转速
					component: () => import(
						'@/page/views/platform/equipment/actualSpeed/list.vue'
					)
				},
                {
					path: 'faultData',
					name: 'platformFaultData', //设备报警-历史故障数据
					component: () => import(
						'@/page/views/platform/equipment/faultData/list.vue'
					)
				}
			]
		},
		//用户中心	
		{
			path: 'user',
			name: 'platformUser',
			component: () => import('@/page/views/platform/user/user.vue'),
			// redirect: "user/company",
			children: [{
					path: 'company',
					name: 'platformCompany',
					component: () => import(
						'@/page/views/platform/user/company/list.vue'
					)
				}, {
					path: 'companyAcc',
					name: 'platformCompanyAcc',
					component: () => import(
						'@/page/views/platform/user/companyAcc/list.vue'
					)
				}, {
					path: 'loginLog',
					name: 'platformLoginLog',
					component: () => import(
						'@/page/views/platform/user/loginLog/list.vue'
					)
				}, {
					path: 'myAcc',
					name: 'platformMyAcc',
					component: () => import(
						'@/page/views/platform/user/myAcc/list.vue'
					)
				}, {
					path: 'myLoginLog',
					name: 'platformMyLoginLog',
					component: () => import(
						'@/page/views/platform/user/myLoginLog/list.vue'
					)
				}, {
					path: 'role',
					name: 'platformRole',
					component: () => import(
						'@/page/views/platform/user/role/list.vue'
					)
				}, {
					path: 'permission',
					name: 'platformPermission',
					component: () => import(
						'@/page/views/platform/user/permission/list.vue'
					)
				}, {
					path: 'operationLog',
					name: 'platformOperationLog',
					component: () => import(
						'@/page/views/platform/user/operationLog/list.vue'
					)
				}, {
					path: 'myOperationLog',
					name: 'platformMyOperationLog',
					component: () => import(
						'@/page/views/platform/user/myOperationLog/list.vue'
					)
				}, {
					path: 'regAndRevExpert',
					name: 'platformExpert',
					component: () => import(
						'@/page/views/platform/user/regAndRev/expert/list.vue'
					)
				},
				{
					path: 'regAndRevSupplier',
					name: 'platformSupplier',
					component: () => import(
						'@/page/views/platform/user/regAndRev/supplier/list.vue'
					)
				}, {
					path: 'subAcc',
					name: 'platformSubAcc',
					component: () => import(
						'@/page/views/platform/user/subAcc/list.vue'
					)
				}, {
					path: 'supplierAcc',
					name: 'platformSupplierAcc',
					component: () => import(
						'@/page/views/platform/user/supplierAcc/list.vue'
					)
				}, {
					path: 'userAcc',
					name: 'platformUserAcc',
					component: () => import(
						'@/page/views/platform/user/userAcc/list.vue'
					)
				}, {
					path: 'zdwxAcc',
					name: 'platformZdwxAcc',
					component: () => import(
						'@/page/views/platform/user/zdwxAcc/list.vue'
					)
				}, {
					path: 'zzjg',
					name: 'platformZzjg',
					component: () => import(
						'@/page/views/platform/user/zzjg/list.vue'
					)
				}
			]
		},
		//统计报表
		{
			path: 'reportStatistics',
			name: 'platformTjbb',
			component: () => import('@/page/views/platform/reportStatistics/reportStatistics.vue'),
			redirect: "reportStatistics/dataInsert",
			children: [{
				path: 'dataInsert',
				name: 'platformDataInsert',
				component: () => import(
					'@/page/views/platform/reportStatistics/dataInsert/list.vue'
				)
			}]
		},
		//系统配置
		{
			path: 'systemSetting',
			name: 'platformSystemSetting',
			component: () => import('@/page/views/platform/systemSetting/systemSetting.vue'),
			// redirect: "systemSetting/appIssue",
			children: [{
				path: 'appIssue',
				name: 'platformAppIssue',
				component: () => import(
					'@/page/views/platform/systemSetting/appIssue/list.vue'
				)
			},{
				path: 'dictionary',
				name: 'platformDictionary',
				component: () => import(
					'@/page/views/platform/systemSetting/dictionary/list.vue'
				)
			}]
		},
		//交易管理
		{
			path: 'transaction',
			name: 'platformTransaction',
			component: () => import('@/page/views/platform/transaction/transaction.vue'),
			children: [{
				path: 'purchaseOrder', //采购订单
				name: 'platformPurchaseOrder',
				component: () => import(
					'@/page/views/platform/transaction/order/purchaseOrder.vue'
				)
			},{
				path: 'leaseOrder', //租赁订单
				name: 'platformLeaseOrder',
				component: () => import(
					'@/page/views/platform/transaction/order/leaseOrder.vue'
				)
			},
			{
				path: 'diagnosisOrder', //诊断订单
				name: 'platformDiagnosisOrder',
				component: () => import(
					'@/page/views/platform/transaction/order/diagnosisOrder.vue'
				)
			},{
				path: 'maintenanceOrder', //维修订单
				name: 'platformMaintenanceOrde',
				component: () => import(
					'@/page/views/platform/transaction/order/maintenanceOrder.vue'
				)
			},
			
			{
				path: 'buyShop', //出售商品
				name: 'platformBuyShop',
				component: () => import(
					'@/page/views/platform/transaction/shop/procurement/buy.vue'
				)
			},
			{
				path: 'leaseShop', //租赁商品
				name: 'platformLeaseShop',
				component: () => import(
					'@/page/views/platform/transaction/shop/lease/lease.vue'
				)
			},{
				path: 'diagnosisShop', //诊修服务
				name: 'platformDiagnosisShop',
				component: () => import(
					'@/page/views/platform/transaction/shop/diagnosis/diagnosis.vue'
				)
			},
			
			{
				path: 'promotion', //推广设置
				name: 'platformPromotion',
				component: () => import(
					'@/page/views/platform/transaction/setting/promotion/list.vue'
				)
			},{
				path: 'hot', //热门设置
				name: 'platformHot',
				component: () => import(
					'@/page/views/platform/transaction/setting/hot/hot.vue'
				)
			},
			{
				path: 'classify', //分类设置
				name: 'platformClassify',
				component: () => import(
					'@/page/views/platform/transaction/setting/classify/list.vue'
				)
			},{
				path: 'brand', //品牌设置
				name: 'platformBrand',
				component: () => import(
					'@/page/views/platform/transaction/setting/brand/list.vue'
				)
			},
			
			]
		},
	]
}

const company = {
	path: '/company',
	name: 'company',
	component: () => import('@/page/views/company/companyIndex.vue'),
	redirect: "/company/monitoring", //直接重定向
	children: [
		{
			path: 'monitoring',// 监控中心
			name: 'companyMonitorings',
			component: () => import('@/page/views/company/monitoring/monitoringHome.vue'),
			redirect: "monitoring/monitorings",
			children: [
				// {
				// 	path: 'version',//系统更新日志
				// 	name: 'companyversion',
				// 	component: () => import(
				// 		'@/page/views/company/monitoring/version.vue'
				// 	)
				// },
				{
					path: 'monitorings', //测点列表
					name: 'companyMonitoring',
					component: () => import(
						'@/page/views/company/monitoring/monitorings/list.vue'
					)
				},
				{
					path: 'Vibration',//诊断概况
					name: 'companyVibration',
					component: () => import(
						'@/page/views/company/monitoring/Vibration/Vibration.vue'
					)
				},
				{
					path: 'temperature',//轴承温度
					name: 'companytemperature',
					component: () => import(
						'@/page/views/company/monitoring/Vibration/temperature.vue'
					)
				},
				{
					path: 'lubrication',//润滑概况
					name: 'companylubrication',
					component: () => import(
						'@/page/views/company/monitoring/Vibration/lubrication.vue'
					)
				},
				{
					path: 'electricMachinery',//电机参数
					name: 'companyelectricMachinery',
					component: () => import(
						'@/page/views/company/monitoring/Vibration/electricMachinery.vue'
					)
				},
				{
					path: 'self',//系统自诊断
					name: 'companyself',
					component: () => import(
					'@/page/views/company/monitoring/Vibration/self/selfs.vue'
					)
				},
				
				
			]
		}, {
			path: 'equipment',// 设备中心
			name: 'companyEquipment',
			component: () => import('@/page/views/company/equipment/equipment.vue'),
			// redirect: "equipment/equipmentStatistics",
			children: [
				{
					path: 'evaluation',//设备中心-设备评价
					name: 'companyEvaluation',
					component: () => import(
						'@/page/views/company/reportStatistics/monitoringScreen/evaluation.vue'
					)
				}, 
				{
					path: 'equipmentStatistics',//报警统计
					name: 'companyEquipmentStatistics',
					component: () => import(
						'@/page/views/company/equipment/statistics/statistics.vue'
					)
				}, 
				{
					path: 'equipmentLine',//设备线
					name: 'companyEquipmentLine',
					component: () => import(
						'@/page/views/company/equipment/equipmentLine/list.vue'
					)
				}, {
					path: 'gather',//采集站
					name: 'companyGather',
					component: () => import(
						'@/page/views/company/equipment/gather/list.vue'
					)
				},
				{
					path: 'healthStatusReport',//健康状况报告
					name: 'companyJkzkbg',
					component: () => import(
						'@/page/views/company/equipment/healthStatusReport/list.vue'
					)
				},
				{
					path: 'report', //设备中心-设备状态分析报告
					name: 'companyReport',
					component: () => import(
						'@/page/views/company/equipment/report/report.vue'
					)
				},
				{
					path: 'diagnosticMaintenanceReport',//诊断维修报告
					name: 'companyZxwxbg',
					component: () => import(
						'@/page/views/company/equipment/diagnosticMaintenanceReport/list.vue'
					)
				},
				{
					path: 'knowledge',//设备知识库
					name: 'companySbzsk',
					component: () => import(
						'@/page/views/company/equipment/knowledge/list.vue'
					)
				},
				{
					path: 'equipmentMaintenanceRecord',//设备中心-设备检修记录
					name: 'companySbjxjl',
					component: () => import(
						'@/page/views/company/equipment/equipmentMaintenanceRecord/list.vue'
					)
				},
				{
					path: 'equipmentSensor', //设备中心-油液传感器
					name: 'companySensor',
					component: () => import(
						'@/page/views/company/equipment/sensor/list.vue'
					)
				},
				{
					path: 'alarmInfo',
					name: 'companyalarmInfo', //设备中心-报警信息
					component: () => import(
						'@/page/views/company/equipment/alarm/alarmInfo.vue'
					)
				},
				{
					path: 'alarmList',//设备中心-历史报警
					name: 'companyalarmList',
					component: () => import(
						'@/page/views/company/equipment/alarm/alarm.vue'
					)
				},
				{
					path: 'alarmMeas',
					name: 'companyalarmMeas',
					component: () => import(
						'@/page/views/company/equipment/alarm/meas.vue'
					)
				},
				{
					path: 'drawing', //设备中心-设备图纸
					name: 'companySbtz',
					component: () => import(
						'@/page/views/company/equipment/drawing/list.vue'
					)
				},
			]
		}, {
			path: 'user',//用户中心
			name: 'companyUser',
			component: () => import('@/page/views/company/user/user.vue'),
			// redirect: "user/subAcc",
			children: [{
				path: 'subAcc',//子账号管理
				name: 'companySubAcc',
				component: () => import(
					'@/page/views/company/user/subAcc/list.vue'
				)
			}, {
				path: 'role',//角色权限
				name: 'companyRole',
				component: () => import(
					'@/page/views/company/user/role/list.vue'
				)
			}, {
				path: 'myLoginLog',//登录日志
				name: 'companyMyLoginLog',
				component: () => import(
					'@/page/views/company/user/myLoginLog/list.vue'
				)
			}, {
				path: 'myOperationLog',//操作日志
				name: 'companyMyOperationLog',
				component: () => import(
					'@/page/views/company/user/myOperationLog/list.vue'
				)
			}, {
				path: 'organization',//组织架构
				name: 'companyZzjg',
				component: () => import(
					'@/page/views/company/user/organization/list.vue'
				)
			}, ]
		}, {
			path: 'reportStatistics',//3D可视化
			name: 'companyreportStatistics',
			component: () => import('@/page/views/company/reportStatistics/reportStatistics.vue'),
			// redirect: "reportStatistics/monitoringScreen",
			children: [{
				path: 'monitoringScreen',//设备智能运维系统
				name: 'companyJkdp',
				component: () => import(
					'@/page/views/company/reportStatistics/monitoringScreen/list.vue'
				)
			},{
				path: 'equipmentMaintenanceEntry',
				name: 'companySbjxlr',
				component: () => import(
					'@/page/views/company/reportStatistics/equipmentMaintenanceEntry/list.vue'
				)
			}, ]
		},
		{
			path: 'diagnosis',//诊断中心
			name: 'companydiagnosis',
			component: () => import('@/page/views/company/diagnosis/diagnosis.vue'),
		}
	]
}
const expert = {
	path: '/expert',
	name: 'expert',
	component: () => import('@/page/views/expert/expertIndex.vue'),
	redirect: "/expert/order",
	children: [
		// 订单中心
		{
			path: 'order',
			name: 'expertOrder',
			component: () => import('@/page/views/expert/order/order.vue'),
			redirect: "order/zdOrder",
			children: [{
				path: 'zdOrder',
				name: 'expertZdOrder',
				component: () => import(
					'@/page/views/expert/order/zdOrder/list.vue'
				)
			}, {
				path: 'wxOrder',
				name: 'expertWxOrder',
				component: () => import(
					'@/page/views/expert/order/wxOrder/list.vue'
				)
			}, {
				path: 'zdwxReport',
				name: 'expertZdwxReport',
				component: () => import(
					'@/page/views/expert/order/zdwxReport/list.vue'
				)
			}, {
				path: 'myService',
				name: 'expertMyService',
				component: () => import(
					'@/page/views/expert/order/myService/list.vue'
				)
			}, ]
		},
		{
			path: 'user',
			name: 'expertUser',
			component: () => import('@/page/views/expert/user/user.vue'),
			redirect: "user/myAcc",
			children: [{
				path: 'myAcc',
				name: 'expertMyAcc',
				component: () => import(
					'@/page/views/expert/user/myAcc/list.vue'
				)
			}, {
				path: 'myLoginLog',
				name: 'expertMyLoginLog',
				component: () => import(
					'@/page/views/expert/user/myLoginLog/list.vue'
				)
			}, {
				path: 'bankAcc',
				name: 'expertBankAcc',
				component: () => import(
					'@/page/views/expert/user/bankAcc/list.vue'
				)
			}, ]
		},
	]
}

const supplier = {
	path: '/supplier',
	name: 'supplier',
	component: () => import('@/page/views/supplier/supplierIndex.vue'),
	children: [
		// 订单中心
		{
			path: 'order',
			name: 'supplierOrder',
			component: () => import('@/page/views/supplier/order/order.vue'),
			redirect: "order/cgOrder",
			children: [{
				path: 'cgOrder',
				name: 'supplierCgOrder',
				component: () => import(
					'@/page/views/supplier/order/cgOrder/list.vue'
				)
			}, {
				path: 'zlOrder',
				name: 'supplierZlOrder',
				component: () => import(
					'@/page/views/supplier/order/zlOrder/list.vue'
				)
			}, {
				path: 'zzOrder',
				name: 'supplierZzOrder',
				component: () => import(
					'@/page/views/supplier/order/zzOrder/list.vue'
				)
			}, {
				path: 'shOrder',
				name: 'supplierShOrder',
				component: () => import(
					'@/page/views/supplier/order/shOrder/list.vue'
				)
			}, {
				path: 'sellShops',
				name: 'supplierSellShops',
				component: () => import(
					'@/page/views/supplier/order/sellShops/list.vue'
				)
			}, {
				path: 'zlShops',
				name: 'supplierZlShops',
				component: () => import(
					'@/page/views/supplier/order/zlShops/list.vue'
				)
			}, ]
		},
		{
			path: 'user',
			name: 'supplierUser',
			component: () => import('@/page/views/supplier/user/user.vue'),
			redirect: "user/subAcc",
			children: [{
				path: 'subAcc',
				name: 'supplierSubAcc',
				component: () => import(
					'@/page/views/supplier/user/subAcc/list.vue'
				)
			}, {
				path: 'permission',
				name: 'supplierPermission',
				component: () => import(
					'@/page/views/supplier/user/permission/list.vue'
				)
			}, {
				path: 'myAcc',
				name: 'supplierMyAcc',
				component: () => import(
					'@/page/views/supplier/user/myAcc/list.vue'
				)
			}, {
				path: 'myLoginLog',
				name: 'supplierLoginLog',
				component: () => import(
					'@/page/views/supplier/user/myLoginLog/list.vue'
				)
			}, {
				path: 'bankAcc',
				name: 'supplierBankAcc',
				component: () => import(
					'@/page/views/supplier/user/bankAcc/list.vue'
				)
			}, ]
		},
		{
			path: 'js',
			name: 'supplierJs',
			component: () => import('@/page/views/supplier/js/js.vue'),
			redirect: "js/menu1",
			children: [{
				path: 'menu1',
				name: 'supplierMenu1',
				component: () => import(
					'@/page/views/supplier/js/menu1/list.vue'
				)
			}]
		},
		{
			path: 'jy',
			name: 'supplierJy',
			component: () => import('@/page/views/supplier/jy/jy.vue'),
			redirect: "jy/menu2",
			children: [{
				path: 'menu3',
				name: 'supplierMenu2',
				component: () => import(
					'@/page/views/supplier/jy/menu2/list.vue'
				)
			}]
		}
	]
}

const routes = [{
		path: '/',
		redirect: 'index', //重定向
		router
	},
	// 官网
	{
		path: '/index',
		component: () => import('@/page/os/officialSite.vue'),
		name: "index"
	},
	//登录页
	{
		path: '/login',
		name: 'login',
		component: () => import('@/page/login/login.vue'),
	},

	{
		path: '/register',
		name: 'register',
		component: () => import('@/page/login/register.vue'),
	},
	{
		path: '/registerZJ',
		name: 'registerZJ',
		component: () => import('@/page/login/registerZJ.vue'),
	},

	{
		path: '/registerGYS',
		name: 'registerGYS',
		component: () => import('@/page/login/registerGYS.vue'),
	},

	{
		path: '/home',
		name: 'home',
		component: () => import('@/page/views/home.vue'),
		children: [
			platform,
			company,
			expert,
			supplier
		]
	},
	// 商城首页
	{
		path: '/Mall',
		name: 'Mall',
		component: () => import('@/page/shoppingMall/index.vue'),
		redirect: 'shopping', //重定向
		children: [
			// 诊断维修
			{
				path: '/diagnosis',
				name: 'diagnosis',
				component: () => import('@/page/shoppingMall/diagnosis/diagnosis.vue')
			},
			// 诊断维修详情
			{
				path: '/diagnosisDetails',
				name: 'diagnosisDetails',
				component: () => import('@/page/shoppingMall/diagnosis/diagnosisDetails.vue')
			},
			// 诊断维修/填写下单页
			{
				path: '/diagnosisOrder',
				name: 'diagnosisOrder',
				component: () => import('@/page/shoppingMall/diagnosis/diagnosisOrder.vue')
			},
			//电商采购
			{
				path: '/shopping',
				name: 'shopping',
				component: () => import('@/page/shoppingMall/shop/shopping.vue')
			},
			//电商采购--列表
			{
				path: '/shoppingList',
				name: 'shoppingList',
				component: () => import('@/page/shoppingMall/shop/list.vue')
			},
			//电商采购--商品详情
			{
				path: '/shopDetails',
				name: 'shopDetails',
				component: () => import('@/page/shoppingMall/shop/shopDetails.vue')
			},
			//电商采购--下单详情
			{
				path: '/shopOrder',
				name: 'shopOrder',
				component: () => import('@/page/shoppingMall/shop/shopOrder.vue')
			},
			//设备租赁
			{
				path: '/lease',
				name: 'lease',
				component: () => import('@/page/shoppingMall/lease/lease.vue')
			},
			//电商采购--列表
			{
				path: 'leaseList',
				name: 'leaseList',
				component: () => import('@/page/shoppingMall/lease/list.vue')
			},
			//设备租赁 --租赁详情
			{
				path: '/leaseDetails',
				name: 'leaseDetails',
				component: () => import('@/page/shoppingMall/lease/leaseDetails.vue')
			},
			//设备租赁--下单详情
			{
				path: '/leaseOrder',
				name: 'leaseOrder',
				component: () => import('@/page/shoppingMall/lease/leaseOrder.vue')
			},
			
			// 购物车
			{
				path: '/shoppingCart',
				name: 'shoppingCart',
				component: () => import('@/page/shoppingMall/cart/shoppingCart.vue')
			},
			// 诊断专家列表页维修师傅列表页(共用)
			{
				path: '/diagnosisExpert',
				name: 'diagnosisExpert',
				component: () => import('@/page/shoppingMall/diagnosis/diagnosisExpert.vue')
			},
			// 维修师傅列表页
			// {
			// 	path: '/maintainMatser',
			// 	name: 'maintainMatser',
			// 	component: () => import('@/page/shoppingMall/maintainMatser.vue')
			// },
			// 提交订单成功页
			{
				path: '/orderSubmit',
				name: 'orderSubmit',
				component: () => import('@/page/shoppingMall/order/orderSubmit.vue')
			},
			// 评价晒单页
			{
				path: '/evaluationShow',
				name: 'evaluationShow',
				component: () => import('@/page/shoppingMall/evaluationShow.vue')
			},
			//订单页面
			{
				path: '/order',
				name: 'order',
				redirect: '/purchaseOrder', //重定向
				component: () => import('@/page/shoppingMall/order/order.vue'),
				children: [{
						// 采购订单页
						path: '/purchaseOrder',
						name: 'purchaseOrder',
						component: () => import('@/page/shoppingMall/order/shopping/purchaseOrder.vue'),
					},
					// 租赁订单
					{
						path: '/leaseOrders',
						name: 'leaseOrders',
						component: () => import('@/page/shoppingMall/order/lease/leaseOrder.vue'),
					},
					// 我的订单-诊断订单
					{
						path: '/mydiagnosisOrder',
						name: 'mydiagnosisOrder',
						component: () => import('@/page/shoppingMall/component/mydiagnosisOrder.vue'),
					},
					// 我的订单-维修订单
					{
						path: '/mymaintainOrder',
						name: 'mymaintainOrder',
						component: () => import('@/page/shoppingMall/component/mymaintainOrder.vue'),
					},
					// 诊断维修订单详情页
					{
						path: '/orderDetail',
						name: 'orderDetail',
						component: () => import('@/page/shoppingMall/order/monitoring/orderDetail.vue'),
					},
					// 设备租赁订单详情页
					{
						path: '/orderDetail-lease',
						name: 'orderDetail-lease',
						component: () => import('@/page/shoppingMall/order/lease/orderDetail.vue'),
					},
					// 电商采购订单详情页
					{
						path: '/orderDetail-shop',
						name: 'orderDetail-shop',
						component: () => import('@/page/shoppingMall/order/shopping/orderDetail.vue'),
					},
					
					// 地址列表页
					{
						path: '/address',
						name: 'address',
						component: () => import('@/page/shoppingMall/address/address.vue')
					},
				]
			},
		]
	}
]


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

// router.beforeEach((to, from, next) => {
// 	if (to.path == '/diagnosis') {
// 		next();
// 	} else {
// 		if (store.state.loginLocation == 'home') {
// 			next();
// 		} else {
// 			//触发改变
// 			store.dispatch("setIsDiagnosisLogined", true);
// 			console.log(store.state.isDiagnosisLogined);
// 			store.dispatch("setIsDiagnosisLogined", false);
// 			console.log(store.state.isDiagnosisLogined);
// 			next(false);
// 		}
// 	}
// })
// 解决重复点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const originalPushs = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
	return originalPush.call(this, location).catch(err => err)
}


export default router

//导入加载进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
//封装请求相关的方法
import axios from 'axios'
import Qs from 'qs'
import caches from "@cache/cache.js"
import store from '../store/index.js'
import router from '../router'
import {
	getHeaderJson
} from '../config/md5.js';
import configApi from '../config/configApi'
//初始化一个axios对象
const instance = axios.create({
	//接口根路径
	baseURL: configApi.baseURL,
	//超时时间
	timeout: 60000,
});

// 添加请求拦截器
instance.interceptors.request.use(
	function(config) {
		// 在发送请求之前做些什么
		// if (
		// 	config.method === "post" ||
		// 	config.method === "put" ||
		// 	config.method === "delete"
		// ) {
		// 	// 序列化
		// 	config.data = Qs.stringify(config.data);
		// }
		let token = caches.token.get();
		//如果有token
		if (token) {
			//格式化一下token，分为各种签名
			let header2 = getHeaderJson(token)
			config.headers = Object.assign(config.headers, header2)
		}
		NProgress.start()
		return config;
	},
	function(error) {
		// 对请求错误做些什么
		NProgress.done()
		return Promise.reject(error);
	});

// 添加响应拦截器
instance.interceptors.response.use(
	function(response) {
		// 请求完成对响应数据做点什么
		NProgress.done()
		if (response.status === 200) {
			let data = response.data;
			if (data.code === 1006) {
				//刷新token
				caches.token.set(data.data);
				return instance(response.config);
			} else if (data.code === 1003 || data.code === 1005 || data.code === 1008 || data
				.code === 1007 || data
				.code === 1009) {
				// 需要登录权限,跳转去登陆页面
				caches.clearCaches();
				router.push({
					name: "login"
				})
			}
		}
		return response;
	},
	function(error) {
		// 对响应错误做点什么
		NProgress.done()
		return Promise.reject(error);
	});

//创建一个get请求方法
let get = async function(url, param) {
	let {
		data
	} = await instance.get(url, {
		params: param
	});
	return data
}

//创建一个download请求方法
let download = async function(url, param) {
	let {
		data
	} = await instance.get(url, {
		responseType: 'blob',
		params: param
	});
	return data
}


//创建一个post请求方法
let post = async function(url, param) {
	let {
		data
	} = await instance.post(url, Qs.stringify(param));
	return data
}


//创建一个postData请求方法
let postData = async function(url, param) {
	let {
		data
	} = await instance.post(url, param);
	return data
}


//上传
let upload = async function(url, param) {
	let {
		data
	} = await instance.post(url, param, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return data
}


//创建一个put请求方法
let put = async function(url, param) {
	let {
		data
	} = await instance.put(url, Qs.stringify(param));
	return data
}
//创建一个delete请求方法
let remove = async function(url, param) {
	let {
		data
	} = await instance.delete(url, {
		data: Qs.stringify(param)
	});
	return data
}
let postJson = async function(url, param) {
	let {
		data
	} = await instance.post(url, param);
	return data

}
// 创建一个 putJson 请求方法
let putJson = async function (url, param) {
    let { data } = await instance.put(url, param);
    return data;
}

export {
	get,
	post,
	put,
	remove,
	postJson,
	postData,
	upload,
	download,
    putJson
}
<template>
	<div @contextmenu.prevent="">
		<router-view></router-view>
	</div>
</template>
<script>
	import cache from './cache/cache.js'
	export default {
		name: "App",
		created() {
			//在页面加载时读取sessionStorage里的状态信息
			if (sessionStorage.getItem("store")) {
				let obj = JSON.parse(sessionStorage.getItem("store"))
				this.$store.replaceState(
					Object.assign({},
						this.$store.state,
						obj
					)
				);
				cache.uniqueID = obj.uniqueID;
			}

			//在页面刷新时将vuex里的信息保存到sessionStorage里
			window.addEventListener("beforeunload", () => {
				sessionStorage.setItem("store", JSON.stringify(this.$store.state));
			});
		},
		mounted() {
			//路由跳转后视图回到顶部
			this.$router.afterEach((to, from, next) => {
				window.scrollTo(0, 0)
			})
			//日期格式化
			Date.prototype.Format = function(fmt) {
				var o = {
					"M+": this.getMonth() + 1, //月份
					"d+": this.getDate(), //日
					"H+": this.getHours(), //小时
					"m+": this.getMinutes(), //分
					"s+": this.getSeconds(), //秒
					"q+": Math.floor((this.getMonth() + 3) / 3), //季度
					S: this.getMilliseconds(), //毫秒
				};
				if (/(y+)/.test(fmt))
					fmt = fmt.replace(
						RegExp.$1,
						(this.getFullYear() + "").substr(4 - RegExp.$1.length)
					);
				for (var k in o)
					if (new RegExp("(" + k + ")").test(fmt))
						fmt = fmt.replace(
							RegExp.$1,
							RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
						);
				return fmt;
			};
		},
	};
</script>
<style lang="scss">
	// * {
	//   letter-spacing: 1px;
	//   font-family: "Microsoft YaHei", SimSun, "\5b8b\4f53", sans-serif;
	//   font-size: 14px;
	// }
	//   公共样式
	@import "./style/common.scss";
	//  官网样式
	@import "./style/os.scss";
	//滚动条
	*::-webkit-scrollbar {
		background-color: #dadada;
		width: 10px;
		border-radius: 5px;
		height: 10px;
	}
	
	*::-webkit-scrollbar-thumb {
		background-color: #409eff;
		border-radius: 5px;
		width: 10px;
		cursor: pointer;//将鼠标样式变为手指形
	}
</style>

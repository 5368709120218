let caches = {
	uniqueID:null,
	token: {
		set(value) {
			if (value) {
				localStorage.setItem('token'+caches.uniqueID, JSON.stringify(value));
			}
		},
		get() {
			return JSON.parse(localStorage.getItem('token'+caches.uniqueID));
		},
		remove() {
			localStorage.removeItem('token'+caches.uniqueID);
		}
	},
	loginLocation: {
		set(value) {
			if (value) {
				localStorage.setItem('loginLocation', JSON.stringify(value));
			}
		},
		get() {
			return JSON.parse(localStorage.getItem('loginLocation'));
		},
		remove() {
			localStorage.removeItem('loginLocation');
		}
	},
	user: {
		set(value) {
			if (value) {
				localStorage.setItem('user'+caches.uniqueID, JSON.stringify(value));
			}
		},
		get() {
			return JSON.parse(localStorage.getItem('user'+caches.uniqueID));
		},
		remove() {
			localStorage.removeItem('user'+caches.uniqueID);
		}
	},
	role: {
		set(value) {
			if (value) {
				localStorage.setItem('role'+caches.uniqueID, JSON.stringify(value));
			}
		},
		get() {
			return JSON.parse(localStorage.getItem('role'+caches.uniqueID));
		},
		remove() {
			localStorage.removeItem('role'+caches.uniqueID);
		}
	},
	topMenu: {
		set(value) {
			if (value) {
				localStorage.setItem('topMenu'+caches.uniqueID, JSON.stringify(value));
			}
		},
		get() {
			return JSON.parse(localStorage.getItem('topMenu'+caches.uniqueID));
		},
		remove() {
			localStorage.removeItem('topMenu'+caches.uniqueID);
		}
	},
	currentTopMenu: {
		set(value) {
			if (value) {
				localStorage.setItem('currentTopMenu'+caches.uniqueID, JSON.stringify(value));
			}
		},
		get() {
			return JSON.parse(localStorage.getItem('currentTopMenu'+caches.uniqueID));
		},
		remove() {
			localStorage.removeItem('currentTopMenu'+caches.uniqueID);
		}
	},
	currentUrl: {
		set(value) {
			if (value) {
				localStorage.setItem('currentUrl'+caches.uniqueID, JSON.stringify(value));
			}
		},
		get() {
			return JSON.parse(localStorage.getItem('currentUrl'+caches.uniqueID));
		},
		remove() {
			localStorage.removeItem('currentUrl'+caches.uniqueID);
		}
	},
	codes: {
		set(value) {
			if (value) {
				localStorage.setItem('codes'+caches.uniqueID, JSON.stringify(value));
			}
		},
		get() {
			return JSON.parse(localStorage.getItem('codes'+caches.uniqueID));
		},
		remove() {
			localStorage.removeItem('codes'+caches.uniqueID);
		}
	},
	clearCaches() {
		caches.token.remove();
		caches.user.remove();
		caches.role.remove();
		caches.currentUrl.remove();
		caches.codes.remove();
		caches.topMenu.remove();
		caches.currentTopMenu.remove();
		caches.uniqueID = null;
	},
	clearAll() {
		caches.token.remove();
		caches.user.remove();
		caches.codes.remove();
		caches.role.remove();
		caches.currentUrl.remove();
		caches.topMenu.remove();
		caches.currentTopMenu.remove();
		caches.uniqueID = null;
	}
}

export default caches;
